<template>
  <div class="Planning">
    <Head :headdata="headdata"></Head>
    <div class="PlanningMain common-main">

      <h2 class="title_zh">
        策划∞
        <div class="fenxBox">
          <img class="fenx" src="../assets/images/icon_fenx.png" alt />
          <p>分享</p>
          <share/>
        </div>
      </h2>
      <h2 class="title_en">PLANNING ∞</h2>
      <div class="htmlContent" v-html="htmlContent"></div>
      <!-- <p class="title_text">
        以资源推荐、建设管理、土地获取、产品研发为诉求，
        为处于不同阶段的项目在顶层设计、创意搭建的阶段，
        为项目提供针对性、定制化的策划咨询服务。
      </p>
      <ul>
        <li v-for="(item,index) in 4" :key="index">
          <h3>面向企业的拿地需求{{index}}</h3>
          <p>替代文本替代文本替代文本替代文本替代文本替代文本替代文本替代文本替代文本替代文本替代文本替代文本替代文本替代文本替代文本替代文本替代文本替代文本</p>
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
import Head from "@/components/header.vue";
import share from "@/components/share.vue";
export default {
  data() {
    return {
      headdata: this.$t("m.onHeaddata"),
      htmlContent:'',
      
    };
  },
  components: {
    Head,
    share
  },
  created(){
    this.getData()
  },
  updated() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    getData() {
      this.axios
        .get(this.GLOBAL.serverSrc + "/wc/business") 
        .then((res) => {
          if(res.data.code==1){
             this.htmlContent=res.data.data.business;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Planning {
  height: 100%;
  width: 100%;
  .PlanningMain {
   width: 100%;
    padding: 78px 200px 0 200px;
    .title_zh {
      color: #000;
      font-size: 34px;
      font-family: 'Microsoft yahei' !important;
    
      .fenxBox {
        float: right;
        cursor: pointer;
        .fenx {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 12px;
          color: #808080;
        }
      }
    }
    .title_en {
      margin-bottom: 60px;
      font-size: 24px;
    }
    .title_text {
      font-size: 18px;
      max-width: 400px;
      margin-bottom: 60px;
      color: #000;
    }
    ul {
      li {
        margin-bottom: 20px;
        min-width: 120px;
        h3 {
          color: #000;
          margin-bottom: 16px;
          font-size: 24px;
        }
        > p {
          max-width: 400px;
          font-size: 14px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>